
import {defineComponent, ref, provide, computed, reactive, toRefs} from "vue";
import itemImage from "@/components/dashboard/itemImage.vue";
import ProductImages from "./components/ProductImages.vue"
import ProductDescription from './components/ProductDescription.vue'
import OtherInformation from './components/OtherInformations.vue'
import Product from "@/models/product";
import * as toastr from "toastr";
import FuncUtils from "@/utils/funcUtils";
import {useLoading} from "vue-loading-overlay";
import {useStore} from "vuex";
import {key} from "@/store/index";
import {useRouter} from "vue-router";
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'addProduct',
  components: {
    itemImage,
    ProductDescription,
    ProductImages,
    OtherInformation,
  },
  setup() {
    const product = ref(new Product());
    const imageFiles = ref([]);
    const addProductRef = ref(null);
    const $loader: any = useLoading();
    const { t } = useI18n()
    const store = useStore(key);
    store.dispatch('category/fetch');
    store.dispatch('category/fetchSub');
    store.dispatch('region/fetch');
    store.dispatch('region/fetchSub');
    store.dispatch('plan/fetch');
    const router = useRouter();

    const state = reactive({
      isActiveTab: 'ProductDescription',
      progress: '33'
    })


    const addProduct = async () => {
      if (FuncUtils.validateFields(product.value, ['id', 'viewedOwnerPhoneNumber', 'viewedBy', 'createdBy', 'createdAt', 'updatedAt'])) {
      //  state.progress = '100';
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
        product.value.createdBy = store.getters['user/get'].id;
       const loader = $loader.show({container: addProductRef.value});
        await store.dispatch('product/add', {product: product.value});
        loader.hide();
        if (!store.getters['product/getError']) {
          toastr.success(store.getters['product/getMessage']);
          return router.push({name: 'Products'})
        } else {
          toastr.error(store.getters['product/getMessage'])
        }
      } else {
        toastr.warning(t('postProductPage.formThree.validationMessage'))
      }
    }

    function goToPage(context) {
      /*// Scroll to a certain element
      document.querySelector('.hello').scrollIntoView({
        behavior: 'smooth'
      });*/
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
      $('.prod-steps.active').removeClass('active');
      state.isActiveTab = context


      switch (context) {
        case 'ProductDescription':
          state.progress = '33';
          $('.prod-steps:eq(0)').addClass('active');
          break;
        case 'ProductImages':
          state.progress = '66';
          $('.prod-steps:eq(1)').addClass('active');
          break;
        case 'OtherInformation':
          state.progress = '100';
          $('.prod-steps:eq(2)').addClass('active');
          break;
      }
    }

    const regions = computed(() => store.getters['region/getRegions'])
    const plans = computed(() => store.getters['plan/plans'])
    const subRegions = computed(() => store.getters['region/getSubRegions'].filter(subRegion => subRegion.regionID === product.value.region))
    const categories = computed(() => store.getters['category/getCategories'])
    const subCategories = computed(() => store.getters['category/getSubCategories'].filter(subCategory => subCategory.categoryID === product.value.category))

    provide('formInfo', [product, imageFiles, regions, plans, subRegions, categories, subCategories])
    return {
      addProduct,
      addProductRef,
      goToPage,
      ...toRefs(state)
    }
  },
})
