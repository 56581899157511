

import {defineComponent, reactive, inject, toRefs} from "vue";
import toastr from "toastr";
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'ProductDescription',
  emits: ['goToPage'],
  setup(props, context) {
    const state = reactive({})
    const { t } = useI18n()

    const [product, imageFiles, regions, plans, subRegions, categories, subCategories] = inject('formInfo') as any[]

    function handleNext(){
      if(!validateProduct()){
        return false
      }
      else context.emit('goToPage', 'ProductImages')
    }

    function validateProduct(){
      if(!product.value.name){
        toastr.warning(t('postProductPage.formOne.nameValidation'))
        return false
      }
      else if(!product.value.price || product.value.price <= 0){
        toastr.warning(t('postProductPage.formOne.priceValidation'))
        return false
      }
      else if(!product.value.category){
        toastr.warning(t('postProductPage.formOne.catValidation'))
        return false
      }
      else if(!product.value.subCategory){
        toastr.warning(t('postProductPage.formOne.subCatValidation'))
        return false
      }
      else if(!product.value.description){
        toastr.warning(t('postProductPage.formOne.descriptionValidation'))
        return false
      }
      return true
    }

    return {
      product,
      categories,
      subCategories,
      handleNext,
      ...toRefs(state)
    }
  },

});
