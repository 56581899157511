

import {defineComponent, reactive, inject, toRefs} from "vue";
import itemImage from "@/components/dashboard/itemImage.vue";
import toastr from "toastr";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'ProductImages',
  emits: ['goToPage'],
  components:{itemImage},
  setup(props, context){
    const state = reactive({

    })
    const { t } = useI18n()

    const [product, imageFiles] = inject('formInfo') as any[]

    function validateProduct(){
      if(product.value.images.length <= 0){
        toastr.warning(t('postProductPage.formTwo.imageValidation'))
        return false
      }
      return true
    }

    function handleNext(){
      if(!validateProduct()){
        return false
      }
      else context.emit('goToPage', 'OtherInformation')
    }


    return{
      product,
      imageFiles,
      handleNext,
      ...toRefs(state)
    }
  },

});
