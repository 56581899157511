
import {defineComponent, reactive, inject, toRefs} from "vue";

export default defineComponent({
  name: 'OtherInformations',
  setup() {
    // const  store = useStore()
    const state = reactive({})

    const [product, imageFiles, regions, plans, subRegions] = inject('formInfo') as any[]

    return {
      product,
      regions,
      subRegions,
      plans,
      ...toRefs(state)
    }
  },

});
